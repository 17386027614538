// import {
//   createStore, combineReducers, applyMiddleware, compose,
// } from 'redux';
// import thunk from 'redux-thunk';
// import OpenSignBoxReducer from './reducers/OpenSignUpBoxReducer/OpenSignUpBoxReducer';

// export const ConfigureStore = () => {
//   const store = createStore(
//     combineReducers({
//       isOpen: OpenSignBoxReducer,
//     }),
//     compose(
//       applyMiddleware(thunk),
//       // @ts-ignore
//       window.devToolsExtension ? window.devToolsExtension() : (f) => f,
//     ),
//   );
//   return store;
// };


///for hash route
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import rootReducer from './reducers'
// import { routerMiddleware } from 'connected-react-router';
// import { createHashHistory } from 'history';
// const history = createHashHistory();
// const routeMiddleware = routerMiddleware(history);

export const ConfigureStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware()/* , routeMiddleware */],
  })

  return store
}
